import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { HttpFooterService } from '../../common/httpservices/httpfooter.service';
import { CommonComponentsModule } from '../common/commoncomponents.module';
import { AppLoaderComponent } from './apploader/apploader.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout.component';
import { OffsideBarComponent } from './offsidebar/offsidebar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UserInfoComponent } from './sidebar/userinfo/userinfo.component';

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, TranslateModule, StoreModule, CommonComponentsModule],
  declarations: [SidebarComponent, UserInfoComponent, LayoutComponent, HeaderComponent, OffsideBarComponent, AppLoaderComponent, FooterComponent],
  exports: [LayoutComponent, HeaderComponent, SidebarComponent, OffsideBarComponent, AppLoaderComponent],
  providers: [HttpFooterService]
})
export class LayoutModule {}
