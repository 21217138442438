import { defaultTranslatedTranslation, defaultTranslation, Translation } from '../models/translation.model';

export const Languages: Translation[] = [
  defaultTranslation,
  {
    code: 'nl-BE',
    displayName: 'Nederlands'
  },
  {
    code: 'fr-BE',
    displayName: 'Français'
  }
];

export const TranslatedLanguages: Translation[] = [
  defaultTranslatedTranslation,
  {
    code: 'nl-BE',
    displayName: 'common.culture.nl-be'
  },
  {
    code: 'fr-BE',
    displayName: 'common.culture.fr-be'
  }
];
