import { NamedResource } from '../../core/models/namedresource.model';
import { SimpleProperty } from '../../parsing/json-property.decorator';

export class LocationResource extends NamedResource {
  public capacity: number;
  public companyId: number;
  constructor(id: number, name: string, capacity?: number, companyId?: number) {
    super(id, name);
    this.capacity = capacity;
    this.companyId = companyId;
  }
  public toString(): string {
    return `${this.name} (${this.capacity})`;
  }
}

export class SessionLocationReadModel extends NamedResource {
  @SimpleProperty() public capacity: number = undefined;
  @SimpleProperty() public companyId: number = undefined;
  public toString(): string {
    return `${this.name} (${this.capacity})`;
  }
}
