import { Injectable } from '@angular/core';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { Observable } from 'rxjs';
import { INamedResource } from '../../../common/core/models/inamedresource.model';

import { HttpService } from '../../../common/core/http/http.service';
import { UriBuilder } from '../../../common/core/http/uri.builder';
import { FaqDetailReadModel } from '../../faq/models/faqdetail.readmodel';
import { FaqResourceReadModel } from '../../faq/models/faqresource.readmodel';
import { FaqLinkModel } from '../../../common/models/Faqs/faq-link.model';

@Injectable()
export class HttpHelpButtonService {
    private _placeholderUri: UriBuilder = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig())
        .withPoApiRoot()
        .withPath('faqpageplaceholder');
    protected get basePlaceholderUri(): UriBuilder {
        return this._placeholderUri.clone();
    }
    private _faqUri: UriBuilder = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withPoApiRoot().withPath('faq');
    protected get baseFaqUri(): UriBuilder {
        return this._faqUri.clone();
    }

    constructor(private httpService: HttpService, private tenantConfigurationFactory: TenantConfigurationFactory) {}

    public getFaqPages(key: string): Observable<INamedResource[]> {
        return this.httpService.get<INamedResource[]>(this.basePlaceholderUri.withPath('faqs').withPath(key).build(), null, true);
    }

    public getFaqLinks(key: string): Observable<FaqLinkModel[]> {
        return this.httpService.get<FaqLinkModel[]>(this.basePlaceholderUri.withPath('links').withPath(key).build(), null, true);
    }

    public getFaqPage(id: number): Observable<FaqDetailReadModel> {
        return this.httpService.get<FaqDetailReadModel>(this.baseFaqUri.withPath('details').withId(id).build(), null, true);
    }

    public downloadFile(id: number, attachment: FaqResourceReadModel, cultureCode: string, isLongRequest: boolean = true): Observable<boolean> {
        return this.httpService.downloadFile(
            this.baseFaqUri.withId(id).withPath(`file/${attachment.id}`).withQueryString('cultureCode', cultureCode).build(),
            isLongRequest
        );
    }
}
