import * as moment from 'moment';

import { DateTimeParser } from '../../parsing/datetime.parser';
import { JsonProperty, SimpleProperty } from '../../parsing/json-property.decorator';
import { SessionAvailabilityModel } from './sessionavailability.model';

export class SessionListModel {
  @SimpleProperty()
  public id: number = undefined;
  @SimpleProperty()
  public name: string = undefined;
  @SimpleProperty()
  public location: string = undefined;
  @SimpleProperty()
  public isExternal: boolean = undefined;
  @SimpleProperty()
  public isOpenSession: boolean = undefined;
  @SimpleProperty()
  public isSharedSession: boolean = undefined;
  @SimpleProperty()
  public isProctorSession: boolean = undefined;
  @JsonProperty(null, SessionAvailabilityModel)
  public faAvailability: SessionAvailabilityModel = undefined;
  @JsonProperty(null, SessionAvailabilityModel)
  public elanPlusAvailability: SessionAvailabilityModel = undefined;

  @JsonProperty<moment.Moment>(null, null, () => new DateTimeParser())
  public startDate: moment.Moment = undefined;
  @JsonProperty<moment.Moment>(null, null, () => new DateTimeParser())
  public endDate: moment.Moment = undefined;

  public toString(): string {
    let session: string;
    const startDate = this.startDate.local().format('LLLL');
    let endDate = this.endDate.local().format('LLLL');
    if (this.startDate.dayOfYear() === this.endDate.dayOfYear()) {
      endDate = this.endDate.local().format('LT');
    }

    if (this.faAvailability) {
      session = `${startDate} - ${endDate} - ${this.locationAttribute} - ${this.faAvailability.toString()}`;
    } else {
      session = `${startDate} - ${endDate} - ${this.locationAttribute}`;
    }

    if (this.location !== null) {
      session = `${session} - ${this.location}`;
    }

    return session;
  }

  public getLabels(): string[] {
    return this.isProctorSession ? ['common.tags.proctor'] : null;
  }

  get description(): string {
    const date = this.startDate.local().format('DD/MM/YYYY HH:mm');

    if (this.faAvailability) {
      return this.location ? `${date} ${this.faAvailability.toString()} (${this.location})` : `${date} ${this.faAvailability.toString()}`;
    } else {
      return this.location ? `${date} (${this.location})` : `${date}`;
    }
  }

  get locationAttribute(): string {
    if (this.isExternal) {
      return '(e)';
    } else {
      return '(i)';
    }
  }
}
