import { ApplicationMenu } from '../core/models/applicationmenu.model';
import { CollapsableMenuItem, MenuItem, LanguageMenuItem } from '../core/models/menu.model';
import { defaultTranslation, Translation } from '../core/models/translation.model';

export class LayoutState {
    showSideNav: boolean;
    showOffSideNav: boolean;
    menuItems: CollapsableMenuItem[];
    footerMenuItems: LanguageMenuItem[];
    applicationMenu: ApplicationMenu;
    currentLanguage: Translation;
    showLoadingIndicatorCounter: number;
}

export const initialLayoutState: LayoutState = {
    showSideNav: true,
    showOffSideNav: false,
    menuItems: [],
    footerMenuItems: [],
    applicationMenu: undefined,
    currentLanguage: defaultTranslation,
    showLoadingIndicatorCounter: 0,
};
