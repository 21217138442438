import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, map, of, tap } from 'rxjs';
import * as rxoperators from 'rxjs/operators';

import { MessageService } from 'app/common/core/services/message.service';
import { GetFooterMenuItemsAction } from 'app/common/layout/layout.actions';
import { CurrentUserService } from '../../core/auth/currentuser.service';
import { HttpAuthService, ImpersonationServerStates } from '../../core/auth/httpauth.service';
import { ImpersonationService } from '../../core/auth/impersonation.service';
import { AppInsightService } from '../../core/services/appinsight.service';
import { AppState } from '../app.state';
import { GetAllNotificationsAction, GetAllScheduledNotificationsAction } from '../notification.actions';
import {
    AuthActionKeys,
    FinalizeImpersonationActionCompleted,
    GetCurrentUserDataCompletedAction,
    ImpersonationStates,
    SetImpersonationStateAction,
    UserImpersonationAction,
    UserImpersonationCompletedAction,
    UserImpersonationFailedAction,
} from './auth.actions';

@Injectable()
export class ImpersonationEffects {
    startImpersonationSession: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType<UserImpersonationAction>(AuthActionKeys.STARTUSERIMPERSONATION),
            rxoperators.mergeMap((action) => {
                this.store.dispatch(new SetImpersonationStateAction(ImpersonationStates.Validation));
                return this.httpService.impersonateUser(action.userId).pipe(
                    rxoperators.map((serverState) => {
                        switch (serverState) {
                            case ImpersonationServerStates.Allowed:
                                this.router.navigate(['/']);
                                return new UserImpersonationCompletedAction(action.userId);
                            case ImpersonationServerStates.NotFound:
                                this.store.dispatch(new SetImpersonationStateAction(ImpersonationStates.UserNotFound));
                                this.resetImpersonationState();
                                return new UserImpersonationFailedAction('common.impersonation.usernotfound');
                            default:
                            case ImpersonationServerStates.NotAllowed:
                                this.store.dispatch(new SetImpersonationStateAction(ImpersonationStates.NotAllowed));
                                this.resetImpersonationState();
                                return new UserImpersonationFailedAction('common.impersonation.notallowed');
                        }
                    })
                );
            })
        );
    });

    completeImpersonationSession: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType<UserImpersonationAction>(AuthActionKeys.STARTUSERIMPERSONATION_COMPLETED),
            rxoperators.mergeMap((action) => {
                this.store.dispatch(new SetImpersonationStateAction(ImpersonationStates.GetCurrentUser));
                this.appinsight.trackEvent(`UserImpersonateStarting`, {
                    userImpersonating: this.currentUserService.userId.toString(),
                    userBeingImpersonating: action.userId.toString(),
                });
                this.impersonationService.setIsImpersonating(action.userId);
                return this.httpService.getCurrentUser().pipe(
                    rxoperators.tap((user) => this.currentUserService.setUser(user)),
                    rxoperators.tap((user) => this.router.navigate(['/'])),
                    rxoperators.map((user) => new GetCurrentUserDataCompletedAction(user))
                );
            })
        );
    });

    finalizeImpersonationSession: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType<UserImpersonationAction>(AuthActionKeys.FINALIZEIMPERSONATION),

            rxoperators.mergeMap((action) => {
                this.appinsight.stopTrackEvent(`UserImpersonateEnding`, {
                    userImpersonating: this.currentUserService.userId.toString(),
                    userBeingImpersonating: this.impersonationService.impersonatingUserId.toString(),
                });
                this.store.dispatch(new SetImpersonationStateAction(ImpersonationStates.Finalize));
                this.impersonationService.setIsImpersonating(null);
                this.messageService.reset();
                return of(new FinalizeImpersonationActionCompleted());
            })
        );
    });

    getAllMenuItems = createEffect(() => {
        return this.actions$.pipe(
            ofType<UserImpersonationAction>(AuthActionKeys.FINALIZEIMPERSONATION_COMPLETED),
            rxoperators.mergeMap((action) => {
                this.store.dispatch(new SetImpersonationStateAction(ImpersonationStates.GetCurrentUser));
                this.store.dispatch(new GetAllNotificationsAction());
                this.store.dispatch(new GetAllScheduledNotificationsAction());
                return this.httpService.getCurrentUser().pipe(
                    tap((user) => this.currentUserService.setUser(user)),
                    tap((user) => this.store.dispatch(new GetFooterMenuItemsAction(user.cultureCode))),
                    tap((_) => this.router.navigate(['/', 'admin', 'users'])),
                    tap((_) => this.store.dispatch(new SetImpersonationStateAction(ImpersonationStates.Disabled))),
                    map((user) => new GetCurrentUserDataCompletedAction(user))
                );
            })
        );
    });

    constructor(
        private store: Store<AppState>,
        private actions$: Actions,
        private httpService: HttpAuthService,
        private currentUserService: CurrentUserService,
        private impersonationService: ImpersonationService,
        private router: Router,
        private appinsight: AppInsightService,
        private messageService: MessageService
    ) {}

    private resetImpersonationState(): void {
        setTimeout(() => {
            this.store.dispatch(new SetImpersonationStateAction(ImpersonationStates.Disabled));
        }, 2500);
    }
}
