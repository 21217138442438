import * as moment from 'moment';

import { NamedResource } from '../../../../../common/core/models/namedresource.model';
import { DateTimeParser } from '../../../../../common/parsing/datetime.parser';
import { JsonProperty, SimpleProperty } from '../../../../../common/parsing/json-property.decorator';

export class OccurrenceSessionReadModel {
  @JsonProperty(null, NamedResource)
  public session: NamedResource = undefined;

  @JsonProperty<moment.Moment>(null, null, () => new DateTimeParser())
  public startDate: moment.Moment = undefined;
  @JsonProperty<moment.Moment>(null, null, () => new DateTimeParser())
  public endDate: moment.Moment = undefined;

  @SimpleProperty()
  public locationName: string = undefined;

  @SimpleProperty()
  public availability: number = undefined;

  public toString(): string {
    return this.dateDescription;
  }

  public get dateDescription(): string {
    if (this.startDate.dayOfYear() === this.endDate.dayOfYear()) {
      return `${this.startDate.local().format('LLLL')} - ${this.endDate.local().format('LT')}`;
    }
    return `${this.startDate.local().format('LLLL')} - ${this.endDate.local().format('LLLL')}`;
  }

  public get locationDescription() {
    return `${this.locationName ? this.locationName : ''} (${this.availability})`;
  }

  public get fullDescription() {
    return `${this.locationDescription} - ${this.dateDescription}`;
  }
}
