import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { TenantConfigurationFactory } from 'app/tenantconfiguration.factory';
import { Observable, of } from 'rxjs';
import * as rxoperators from 'rxjs/operators';

import { moduleTranslateIdentifiers } from '../constants/translate.constants';
import { UriBuilder } from '../http/uri.builder';
import { getPaths } from './httptranslate.helper';






@Injectable()
export class HttpTranslateLoader implements TranslateLoader {
  private _paths: string[] = null;
  private get paths(): string[] {
    if (this._paths == null) {
      this._paths = getPaths(moduleTranslateIdentifiers);
    }
    return this._paths;
  }

  constructor(
    private httpClient: HttpClient,
    private tenantConfigurationFactory: TenantConfigurationFactory) { }

  getTranslation(lang: string): Observable<any> {
    const uri = new UriBuilder(this.tenantConfigurationFactory.getTenantConfig()).withPoApiRoot().withPath('translations');
    return this.httpClient
      .get<any>(
        uri
          .clone()
          .withPath('translations')
          .withPath(lang)
          .build(),
        { headers: { 'app-long-request': 'true' } }
      )
      .pipe(
        rxoperators.catchError(err => of({})),
        rxoperators.map(data => Object.assign({}, data))
      );
  }
}
