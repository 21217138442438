import { Action } from '@ngrx/store';
import { ApplicationMenu } from '../core/models/applicationmenu.model';
import { Translation } from '../core/models/translation.model';
import { MenuItem, LanguageMenuItem } from './../core/models/menu.model';

export const layoutActionKeys = {
    OPENSIDENAV: 'layout.sidenav.open',
    CLOSESIDENAV: 'layout.sidenav.close',
    OPENOFFSIDENAV: 'layout.offsidenav.open',
    CLOSEOFFSIDENAV: 'layout.offsidenav.close',
    GETMENUITEMS: 'layout.menuitem.get',
    GETMENUITEMS_COMPLETED: 'layout.menuitem.getcompleted',
    GETAPPLICATIONMENU: 'layout.applicationmenu.get',
    GETAPPLICATIONMENU_COMPLETED: 'layout.applicationmenu.getcompleted',
    GETFOOTERMENUITEMS: 'layout.footermenuitems.get',
    GETFOOTERMENUITEMS_COMPLETED: 'layout.footermenuitems.getcompleted',
    SETTRANSLATIONLANGUAGE: 'layout.translation.set',
    SETTRANSLATIONLANGUAGE_COMPLETED: 'layout.translation.setcompleted',
    SHOWAPPLOADINGINDICATOR: 'layout.apploadingindicator.show',
    HIDEAPPLOADINGINDICATOR: 'layout.apploadingindicator.hide',
};

export class OpenSidenavAction implements Action {
    readonly type = layoutActionKeys.OPENSIDENAV;
}

export class CloseSidenavAction implements Action {
    readonly type = layoutActionKeys.CLOSESIDENAV;
}

export class OpenOffSidenavAction implements Action {
    readonly type = layoutActionKeys.OPENOFFSIDENAV;
}

export class CloseOffSidenavAction implements Action {
    readonly type = layoutActionKeys.CLOSEOFFSIDENAV;
}

export class GetMenuItemsAction implements Action {
    readonly type = layoutActionKeys.GETMENUITEMS;
}

export class GetMenuItemsCompletedAction implements Action {
    readonly type = layoutActionKeys.GETMENUITEMS_COMPLETED;
    constructor(public menuItems: MenuItem[]) {}
}

export class GetFooterMenuItemsAction implements Action {
    readonly type = layoutActionKeys.GETFOOTERMENUITEMS;
    constructor(public cultureCode: string) {}
}

export class GetFooterMenuItemsCompletedAction implements Action {
    readonly type = layoutActionKeys.GETFOOTERMENUITEMS_COMPLETED;
    constructor(public menuItems: LanguageMenuItem[]) {}
}

export class GetApplicationMenuAction implements Action {
    readonly type = layoutActionKeys.GETAPPLICATIONMENU;
}

export class GetApplicationMenuCompletedAction implements Action {
    readonly type = layoutActionKeys.GETAPPLICATIONMENU_COMPLETED;
    constructor(public applicationMenu: ApplicationMenu) {}
}

export class SetLanguageAction implements Action {
    readonly type = layoutActionKeys.SETTRANSLATIONLANGUAGE;
    constructor(public language: Translation) {}
}

export class SetLanguageCompletedAction implements Action {
    readonly type = layoutActionKeys.SETTRANSLATIONLANGUAGE_COMPLETED;
    constructor(public language: Translation) {}
}

export class ShowAppLoadingIndicatorAction implements Action {
    readonly type = layoutActionKeys.SHOWAPPLOADINGINDICATOR;
}

export class HideAppLoadingIndicatorAction implements Action {
    readonly type = layoutActionKeys.HIDEAPPLOADINGINDICATOR;
}

export type LayoutActions =
    | OpenSidenavAction
    | CloseSidenavAction
    | GetMenuItemsAction
    | GetMenuItemsCompletedAction
    | GetApplicationMenuAction
    | GetApplicationMenuCompletedAction
    | GetFooterMenuItemsAction
    | GetFooterMenuItemsCompletedAction
    | SetLanguageAction
    | SetLanguageCompletedAction
    | CloseOffSidenavAction
    | OpenOffSidenavAction
    | ShowAppLoadingIndicatorAction
    | HideAppLoadingIndicatorAction;
