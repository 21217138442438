import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as rxoperators from 'rxjs/operators';

import { MenuService } from './../core/services/menu.service';
import {
    GetApplicationMenuAction,
    GetApplicationMenuCompletedAction,
    GetFooterMenuItemsAction,
    GetFooterMenuItemsCompletedAction,
    GetMenuItemsAction,
    GetMenuItemsCompletedAction,
    layoutActionKeys,
} from './layout.actions';

@Injectable()
export class NavigationEffects {
    getAllMenuItems = createEffect(() => {
        return this.actions$.pipe(
            ofType<GetMenuItemsAction>(layoutActionKeys.GETMENUITEMS),
            rxoperators.map((action) => new GetMenuItemsCompletedAction(this.menuService.menuItems))
        );
    });

    getFooterMenuItems = createEffect(() => {
        return this.actions$.pipe(
            ofType<GetFooterMenuItemsAction>(layoutActionKeys.GETFOOTERMENUITEMS),
            rxoperators.map((action) => new GetFooterMenuItemsCompletedAction(this.menuService.getFooterMenuItemsForLanguage(action.cultureCode)))
        );
    });

    getApplicationMenu = createEffect(() => {
        return this.actions$.pipe(
            ofType<GetApplicationMenuAction>(layoutActionKeys.GETAPPLICATIONMENU),
            rxoperators.map((action) => new GetApplicationMenuCompletedAction(this.menuService.applicationMenuItems))
        );
    });
    constructor(private actions$: Actions, private menuService: MenuService) {}
}
