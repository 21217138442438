import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LayoutState } from './layout.state';

export const getLayoutState = createFeatureSelector<LayoutState>('layout');

export const layoutSelectors = {
    sidenavSelector: createSelector(getLayoutState, (state: LayoutState) => state.showSideNav),
    offSidenavSelector: createSelector(getLayoutState, (state: LayoutState) => state.showOffSideNav),
    menuItemSelector: createSelector(getLayoutState, (state: LayoutState) => state.menuItems),
    footerMenuItemsSelector: createSelector(getLayoutState, (state: LayoutState) => state.footerMenuItems),
    applicationMenuSelector: createSelector(getLayoutState, (state: LayoutState) => state.applicationMenu),
    currentLanguageSelector: createSelector(getLayoutState, (state: LayoutState) => state.currentLanguage),
    showLoadingIndicator: createSelector(getLayoutState, (state: LayoutState) => state.showLoadingIndicatorCounter !== 0),
};
